const Reportes = () => {

    return(
        <div>
            <p>
                 Reportes
            </p>
        </div>
    )
}
export default Reportes