import React from 'react';
import { UncontrolledCarousel } from 'reactstrap';
import imgReunion from '../Imagenes/fotoreunion.png';
import imglogo from '../Imagenes/LOGO APP31200x600.png';


function NoStrictMode({ children }) {
  return <React.Fragment>{children}</React.Fragment>;
}

const items = [
  {
    altText: 'Slide 1',
    caption: 'Proyecto Cordoba',
    key: 1,
    src: imgReunion,
    headerStyle: { width: '100%', height: '600%' } // Ajusta estos valores a lo que necesites
  
  },
  {
    altText: 'Slide 2',
    caption: 'Slide 2',
    key: 2,
    src: imglogo
  },
  {
    altText: 'Slide 3',
    caption: 'Slide 3',
    key: 3,
    src: 'https://picsum.photos/id/678/1200/600'
  }
];

function Creatividad() {
  return (
    <NoStrictMode>
      <UncontrolledCarousel items={items} />
    </NoStrictMode>
  );

}

export default Creatividad;
