import React from 'react';
import {
  Navbar,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import logo from '../Imagenes/LOGO APP2.PNG';

function NavbarNav(args) {
  
  return (
    <div>
         <Navbar {...args} color='light' light expand='md'>
       <NavbarBrand href="/home"><img src={logo} alt="Logo" style={{width: 650, margin: -6, height: 100}} /></NavbarBrand>
          <Nav className="ms-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Mario Bonilla
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem>Mi perfil</DropdownItem>
                <DropdownItem>Configuración</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Cerrar Sesión</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
      </Navbar>
      </div>
  );
}

export default NavbarNav;