import './App.scss';
import Sidebar from './components/Sidebar';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Psicosocial from './pages/Psicosocial'
import Perfiles from './pages/Perfiles'
import Mensajes from './pages/Mensajes'
import Reportes from './pages/Reportes'
import NavbarNav from './components/NavbarNav';
import Home from './pages/Home';
import Carga from './pages/Carga';
import Familias from './pages/Familias';
import Caracterizacion from './pages/Caracterizacion';
import Momentos from './pages/Momentos';



function App() {
  return (
    <Router >
     
      <div className='flex'>
      <Sidebar/>
      <div className='content w-100'>
      <NavbarNav/>
      <Routes>
      <Route path='/home' exact={true} Component={Home}  />
      <Route path='/carga' exact={true} Component={Carga}  />
      <Route path='/familias' exact={true} Component={Familias}  />
      <Route path='/caracterizacion' exact={true} Component={Caracterizacion}  />
      <Route path='/momentos' exact={true} Component={Momentos}  />
      <Route path='/perfiles' exact={true} Component={Perfiles}  />
      <Route path='/psicosocial' exact={true} Component={Psicosocial}  />
      <Route path='/mensajes' exact={true} Component={Mensajes}  />
      <Route path='/reportes' exact={true} Component={Reportes}  />
     

      </Routes>
      </div>
      </div>
    </Router>
  );
}

export default App;
