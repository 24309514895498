import React from 'react'
import Creatividad from '../components/Creatividad'

const Home = () => {

    return(
        <div>
        
            
            <Creatividad />

        </div>
    )
}
export default Home