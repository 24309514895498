const Psicosocial = () => {

    return(
        <div>
            <p>
                Modelo Psicosocial
            </p>
        </div>
    )
}
export default Psicosocial

