import React, { useState } from 'react';
import axios from 'axios';

const ExcelFile = () => {
  
  const [archivos, setArchivos] = useState(null);

  const subirArchivos = e => {
    setArchivos(e.target.files);
  }

  const insertarArchivos = async () => {
    const f = new FormData();
    
    for(let i = 0; i < archivos.length; i++){
      f.append("files", archivos[i]);
      console.log("archivos", archivos[i])
    }

    await axios.post("http://localhost:3001/api/upload", f)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

   return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '10vh'
    }}>
      <input type='file' name='files' multiple onChange={subirArchivos} accept=".xlsx,.csv" />
      <button className='btn btn-primary' style={{marginTop: '20px'}} onClick={insertarArchivos}>Cargar Archivo</button>
    </div>
  );
};
export default ExcelFile;




// import React, { useState } from 'react';
// import axios from 'axios';

// const ExcelFile = () => {
  
//   const [archivos, setArchivos] = useState(null);

//   const subirArchivos = e => {
//     const files = e.target.files;
//     const allowedExtensions = ["xlsx", "csv"];

//     // Filtrar archivos por extensión
//     const archivosPermitidos = Array.from(files).filter(file => {
//       const extension = file.name.split('.').pop().toLowerCase();
//       return allowedExtensions.includes(extension);
//     });

//     // Asignar archivos permitidos al estado
//     setArchivos(archivosPermitidos);
//   }

//   const insertarArchivos = async () => {
//     if (!archivos || archivos.length === 0) {
//       console.log("Por favor selecciona al menos un archivo válido.");
//       return;
//     }

//     const f = new FormData();
  
//     for(let i = 0; i < archivos.length; i++){
//       f.append("files", archivos[i]);
//     }
  
//     try {
//       const response = await axios.post("/api/upload", f);
//       console.log(response.data);
//     } catch (error) {
//       console.error(error);
//     }
//   }
  

//   return (
//     <div style={{
//       display: 'flex',
//       flexDirection: 'column',
//       alignItems: 'center',
//       justifyContent: 'center',
//       height: '10vh'
//     }}>
//       <input type='file' name='files' multiple onChange={subirArchivos} accept=".xlsx,.csv" />
//       <button className='btn btn-primary' style={{marginTop: '20px'}} onClick={insertarArchivos}>Cargar Archivo</button>
//     </div>
//   );
// };

// export default ExcelFile;

