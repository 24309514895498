import{ NavLink } from 'react-router-dom'
import * as FaIcons from 'react-icons/fa'
const Sidebar = () =>{
    return(
        <div className="sidebar bg-light">
        <ul>

        {/* <li class="nav-item dropdown">
  <NavLink to="/home" exact="true" className='text-dark rounded py-2 w-100 d-inline-block px-3 dropdown-toggle' id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    Cargue de archivos
  </NavLink>
  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
    <li><NavLink to="/ruta1" className='dropdown-item'>Opción 1</NavLink></li>
    <li><NavLink to="/ruta2" className='dropdown-item'>Opción 2</NavLink></li>
    <li><NavLink to="/ruta3" className='dropdown-item'>Opción 3</NavLink></li>
  </ul>
</li> */}
            <li>
                <NavLink to="/home" exact="true" className='text-dark rounded py-2 w-100 d-inline-block px-3'    activeclassname='active'> <FaIcons.FaHome className='me-2'/>  Home</NavLink>
            </li>
                     
<li className="nav-item dropdown">
  <NavLink to="/carga" exact="true" className='text-dark rounded py-2 w-100 d-inline-block px-3 dropdown-toggle' activeclassname='active' id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    Cargue de archivos
  </NavLink>
  <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
    <li><NavLink to="/familias" className='dropdown-item'>Familias Inscritas</NavLink></li>
    <li><NavLink to="/caracterizacion" className='dropdown-item'>Caracterización</NavLink></li>
    <li><NavLink to="/momentos" className='dropdown-item'>Momentos</NavLink></li>
  </ul>
</li>

            {/* <li>
                <NavLink to="/home" exact="true"  className='text-dark rounded py-2 w-100 d-inline-block px-3'    activeclassname='active'>Cargue de archivos</NavLink>
            
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><NavLink to="/ruta1" className='dropdown-item'>Opción 1</NavLink></li>
              <li><NavLink to="/ruta2" className='dropdown-item'>Opción 2</NavLink></li>
              <li><NavLink to="/ruta3" className='dropdown-item'>Opción 3</NavLink></li>
              </ul>  
            </li> */}
        
            
            <li>
                <NavLink to="/perfiles" exact="true" className='text-dark rounded py-2 w-100 d-inline-block px-3'    activeclassname='active'>Generar Perfiles</NavLink>
            </li>
            <li>
                <NavLink to="/psicosocial" exact="true" className='text-dark rounded py-2 w-100 d-inline-block px-3' activeclassname='active'>APP Modelo Psicosocial</NavLink>
            </li>

            <li>
                <NavLink to="/mensajes" exact="true" className='text-dark rounded py-2 w-100 d-inline-block px-3' activeclassname='active'>Mensajes</NavLink>
            </li>
            <li>
                <NavLink to="/reportes" exact="true" className='text-dark rounded py-2 w-100 d-inline-block px-3' activeclassname='active'>Reportes</NavLink>
            </li>
           
        </ul>

        </div>
    )

}


export default Sidebar


