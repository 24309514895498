import React from 'react'

const Mensajes = () => {

    return(
        <div>
            <p>
                Mensajes
            </p>
        </div>
    )
}
export default Mensajes