import React from 'react'
import ExcelFile from '../components/ExcelFile'

const Caracterizacion = () => {

    return(
        <div>
            <br/> 
            <ExcelFile />
            

        </div>
    )
}
export default Caracterizacion